import { couponCodeStore } from "../coupon/coupon"

export default {
    async setCartCount() {
       try {
           const res = await $fetch(useCountryPath('/cart/count'))
           this.commitMutation('setcartCount', res?.data)
       } catch (error) {
           console.log(error,'error in count api')
       }
    },

    async setCartListing(data) {
        
        const storeCouponCode = couponCodeStore()
        // 'vuexContext.rootState.couponModule.couponCode'
        let coupon = storeCouponCode.getCouponCode()

        
        if (data && data.coupon) {
            if (coupon != data.coupon) {
                coupon = data.coupon
            }
        }

        try {
            const res = await $fetch(useCountryPath('/cart?coupon=' + coupon))
            if( import.meta.client ){ 
                
                // alert(['000---------', useCountryPath('/cart?coupon=' + coupon), '-----res.success------', res.success, 'res.data', JSON.stringify(res.data)])
            }
            if (!res.success) {
                throw new Error('failed to load data')
            }

            if (res.success) {
                this.setCartCount()
                this.commitMutation('setCartListing', res.data)
                this.commitMutation('setCartTotal', res.data)
                if (res.data.coupon.applied && res.data.coupon.success) {
                    storeCouponCode.setCouponToStorage(coupon)
                }
            }

            return res

        } catch (error) {
            console.log(error.message, 'setCartListing error - catch')
            // throw error
            if (error.status === 422) {
                // this.commitMutation('setErrorData', error.response.data.errors)
            }
        }
    },

    async removeFromCart(item) {
        const { $toast, $removefromCartGtag } = useNuxtApp()
        try {
            const res = await $fetch(useCountryPath('/cart/remove'), {
                method: 'POST',
                body: { module_type: item.type, item_id: item.id }
            })

            if (!res.success) {
                throw new Error('failed to load data')
            }

            if (res.success) {

                this.commitMutation('removeFromCart', item.id)
                this.setCartListing(null)

                $removefromCartGtag(item.gtag)
                this.setCartCount()
                $toast('success', 'Congratulations!', 'Item removed from cart');
            }

            return res

        } catch (error) {
            alert('please refresh the page and try again', error)
        }
    },

    async addAppraisal(item) {
        
        const { $toast } = useNuxtApp()
        const storeCouponCode = couponCodeStore()

        let coupon = storeCouponCode.getCouponCode()

        try {

            // const coupon = 'vuexContext.getters.getCouponCode'

            const res = await $fetch(useCountryPath('/cart/appraisal/add'), {
                method: 'POST',
                body: { module_type: item.type, item_id: item.id, coupon: coupon }
            })

            if (!res.success) {
                throw new Error('failed to load data')
            }

            if (res.success) {
                this.commitMutation('setCartTotal', res.data)
                this.commitMutation('updateAppraisal', item.id)
                $toast('success', 'Congratulations!', 'Appraisal Updated Successfully');
                // this.$initiateAffirm();
            }

            return res

        } catch (error) {
            alert('please refresh the page and try again', error)
        }
    },

    async removeAppraisal(item) {
        
        const { $toast } = useNuxtApp()
        const storeCouponCode = couponCodeStore()

        let coupon = storeCouponCode.getCouponCode()

        
        try {

            // const coupon = 'vuexContext.getters.getCouponCode'

            const res = await $fetch(useCountryPath('/cart/appraisal/remove'), {
                method: 'POST',
                body: { module_type: item.type, item_id: item.id, coupon: coupon }
            })

            if (!res.success) {
                throw new Error('failed to load data')
            }

            if (res.success) {
                this.commitMutation('setCartTotal', res.data)
                this.commitMutation('updateAppraisal', item.id)
                $toast('success', 'Congratulations!', 'Appraisal Updated Successfully');
                // this.$initiateAffirm();
            }

            return res

        } catch (error) {
            alert('please refresh the page and try again', error)
        }
    },

    async changeCurrency(currency) {

        await this.commitMutation('changeCurrency', currency)
    },

}