import { cartStore } from '../cart/cart';
import { addressStore } from '../address/address';

export default {
    getCouponData: (state) => (page) => {
        const storeCart = cartStore()
        const storeAddress = addressStore()

        let data = null
        if (page === 'cart') {
            data = storeCart.getCartCouponData()
        }
        if (page === 'address') {
            data = storeAddress.getAddressCouponData
        }
        return data;
    },

    getCouponCode: (state) => () => {
        return state.couponCode;
    }
}