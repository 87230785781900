export default {

    getCartData: (state) => () => {
        return state?.cartData?.data ? state?.cartData?.data : null ; 
    },
    getAddressCouponData(state){
        return state.cartData.data?.coupon
    },
    getCartProductDataByModule: (state, getters) => (type) => {

        const data = state.cartData
        if ( ! data?.data?.items) {

            return null
        }

        return data.data.items.filter( product =>  product.type === type)
    },
    getCartTotal: (state) => () => {
        return state.cartTotal;
    },
    
    getGuestData: (state) => () => {
        return state.guestData;
    },
    getaddressData: (state) => () => {
        return state.addressData;
    },
    geterrorData: (state) => () => {
        return state.errorData;
    },
    getAddressId: (state) => () => {
        return state.address_id;
    }
}