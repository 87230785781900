import { cartStore } from '../cart/cart';
import { addressStore } from '../address/address';

export default {
    async setCouponCode(data) {
        const storeCart = cartStore()
        const storeAddress = addressStore()
        
        this.setCouponToStorage(data.coupon)

        if (data.page === 'cart') {

            storeCart.setCartListing({ coupon: data.coupon })
            // this.commitMutation('setCouponToStorage',coupon)
        }

        if (data.page === 'address') {

            storeAddress.setCartData({ coupon: data.coupon })
            // this.commitMutation('setCouponToStorage',coupon)
        }
        if (data.page === 'payment') {
            storeAddress.setCartData({ coupon: data.coupon })
        }
    },

    setCouponToStorage(coupon) {
        return this.commitMutation('setCouponToStorage', coupon)
    },


}