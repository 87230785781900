import { defineNuxtPlugin } from '#app'


export default defineNuxtPlugin(nuxtApp => {


  return
  nuxtApp.hook('page:finish', async () => {
    try {
      const response = await fetch('/version.json');
      const { version: latestVersion } = await response.json();

      const clientStoredVersion = getClientAppVersion();

      // Check if localStorage item is set, i.e., first load
      if (clientStoredVersion === '0') {
        setClientAppVersion(latestVersion);
        return;
      }

      if (clientStoredVersion !== latestVersion) {
        window.location.reload(true);
        setClientAppVersion(latestVersion);
      }
    } catch (error) {
      console.error('Error fetching version:', error);
    }
  });
});
