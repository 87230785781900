import { ofetch } from 'ofetch'
import { useRuntimeConfig } from "#app";
// import { useLogger } from '@nuxt/kit'
import { useCookie } from "#app";
import  { joinURL  } from 'ufo';


export default defineNuxtPlugin(async (_nuxtApp) => {

	const config = useSanctumConfig();
	

	const COOKIE_OPTIONS = { readonly: true };


	async function initCsrfCookie(config, logger) {
		await $fetch(config.endpoints.csrf, {
		  baseURL: config.baseUrl,
		  credentials: "include"
		});
		// logger.debug("CSRF cookie has been initialized");
	}

	async function useCsrfHeader(headers, config, logger) {
		

		let csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
		// console.log('csrfToken', csrfToken)

		if (!csrfToken.value) {
		  await initCsrfCookie(config, logger);
		  csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
		}
		if (!csrfToken.value) {
		//   logger.warn(
		// 	`${config.csrf.cookie} cookie is missing, unable to set ${config.csrf.header} header`
		//   );
		  return headers;
		}
		// // logger.debug(`Added ${config.csrf.header} header to pass to the API`);

		// console.log('-----------------', {
		// 	...headers,
		// 	...csrfToken.value && {
		// 	  [config.csrf.header]: csrfToken.value
		// 	}
		//   })
		return {
		  ...headers,
		  ...csrfToken.value && {
			[config.csrf.header]: csrfToken.value
		  }
		};
	  }

	//   test commit


    globalThis.$fetch = ofetch.create({

		retry: 4,
        retryStatusCodes: [419],
        retryDelay: 500, // can safely delete this


		async onRequest ({ request, options }) {

			// const { locale, setLocale } = useI18n()
			const {$i18n} = useNuxtApp();
			const runtimeConfig = useRuntimeConfig()
			const config = useSanctumConfig();
			const logger = false



			options.headers = { 
				'Accept': 'application/json'
			}

			if( import.meta.client ){

				options.withCredentials  = true,
				options.credentials ='include'
			}

			// test comit

			if( request == '/sanctum/csrf-cookie' ){

			}

		
			// const basePath = joinURL(runtimeConfig.public.baseURL, $i18n.locale.value)

			// if( ! request.includes( basePath ) ){
				
			// 	request = request.replace( runtimeConfig.public.baseURL,  `${basePath}`)
			// }

			// console.log('request ------------', request	)

			// alert(options.method)
			
			if(options.method === 'POST'){
				options.headers = await useCsrfHeader(options.headers, config, logger)
			}
		
			// debugger


		},
		onRequestError ({ error }) {
			// console.error(error)
		},
        
		onResponse ({ response }) {
			// console.error(error)
            // if (response._data && response._data.data) {
            //     response._data = response._data.data;
            // }
            // console.log('response', response)
            return response;
        
		}
    })
})