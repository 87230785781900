
export default {
    setCouponToStorage(state, coupon) {
        if (coupon) {
            state.couponCode = coupon
        }
        else {
            state.couponCode = ''
        }
    },
}