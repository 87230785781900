<template>
    <div>
        <div class="top_bar" v-if="$country.is_india">
            <NuxtLink :to="localePath('/engagement-rings')">Get 35% off* on Engagement Rings | Shop Now</NuxtLink>
        </div>
        <div v-else>
            <div class="top_bar top_bar_mod">
                <Swiper 
                :modules="[SwiperAutoplay]"
                :autoplay="{
                    delay:3000,
                    disableOnInteraction:false,
                }"
                :loop="true"
                class="top-bar-swiper"
                :direction="'vertical'"
                :speed="600"
                
                >
                    <SwiperSlide>
                            <div class="text-us m-0 flex-wrap">
                                <!-- <p class="m-0">Black Friday Sale!&nbsp;</p> -->
                                <!-- <p class="m-0">CYBER MONDAY Sale!&nbsp;</p>
                                <span class="pipe"> | &nbsp;</span> 
                                <p class="m-0">Get Up To 55% OFF*</p> -->
                                <router-link :to="localePath('/engagement-rings')"><p class="mb-0">‘Tis the season to say ‘YES’! Flat 40% off* Sitewide.</p></router-link>
                            </div>
                    </SwiperSlide>
                    <!-- <SwiperSlide>
                            <div class="text-us m-0 flex-wrap">
                                <p class="mb-0">This year let the ring be the star! Shop engagement rings now to get in time for Christmas</p>
                            </div>
                    </SwiperSlide> -->
                </Swiper>
            </div>
        </div>
        <!-- <CommonCustomModal v-model="flagShowModal" :size="'lg'"  :no_padd="true">
			<ModalTermsAndConditionsBF />
        </CommonCustomModal> -->
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp() 
    // const flagShowModal = ref(false);
	// const showModal = () => {
    //     flagShowModal.value = true;
    // };

    // const openPopup = ref(false)

    // const hideModal = () => {
    //     openPopup.value = false
    // }


</script>

<style scoped>



.uppercase{
    text-decoration: underline;
}

.top_bar {
    background: #405955;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
}
.top_bar_mod{
    background: #570909;
    cursor: pointer;
    min-height: 35px;
}

.top-bar-swiper{
    max-height: 35px;
}
.top_bar a, .top_bar .text-us {
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-us{
    text-transform: uppercase;
}

.black_friday_stripe{
    background: #5c041d;
    text-align: center;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
}
.top_bar_mod br{
    display: none;
}


@media(max-width: 1024px) {
    .top_bar a, .top_bar .text-us  {
        width: auto;
    }
}

@media(max-width: 767px){
    .top_bar{
        padding: 5px;
    }
    .top_bar_mod br{
        display: block;
    }
    /* .pipe{
        display: none;
    } */

    .top-bar-swiper{
        max-height: 45px;
    }

    .top_bar_mod{
        min-height: 45px;
    }
}
</style>
