import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import georedirect_45global from "/vercel/path0/middleware/georedirect.global.js";
import redirect_45trailing_45slash_45global from "/vercel/path0/middleware/redirect-trailing-slash.global.js";
import redirect_45global from "/vercel/path0/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  georedirect_45global,
  redirect_45trailing_45slash_45global,
  redirect_45global
]
export const namedMiddleware = {
  "sanctum:auth": () => import("/vercel/path0/modules/m/nuxt-auth-sanctum-main/runtime/middleware/sanctum.auth.ts")
}