import  { joinURL  } from 'ufo';

export function useCountryPath( inputPath ) {

  const runtimeConfig = useRuntimeConfig()

  const {$i18n} = useNuxtApp();

  const basePath = joinURL(runtimeConfig.public.baseURL, $i18n.locale.value)

  const path = joinURL(basePath, inputPath)
  return path
}
