export default defineNuxtPlugin((nuxtApp) => {
    return{
		provide:{
			formatCarat: (carat) => {
				const removeCt = carat.replace("ct", "");

				if (removeCt.includes(" ")) {
					const [beforeSpace, afterSpace] = removeCt.split(/\s+/);
					const [beforeSlash, afterSlash] = afterSpace.split('/');
		
					return `<div class="format_carat mb-0">
							<p class="mb-0 digit">${beforeSpace}</p>
							<p class="mb-0 fraction">
								<span class="sub">${beforeSlash}</span>
								<span class="slash">/</span>
								<span class="sup">${afterSlash}</span>
							</p>
							</div>`;
				}
		
				if (!removeCt.includes(" ") && removeCt.includes("/")) {
					const [beforeSlash, afterSlash] = removeCt.split('/');
		
					return `<div class="format_carat mb-0">
							<p class="mb-0 fraction">
								<span class="sub">${beforeSlash}</span>
								<span class="slash">/</span>
								<span class="sup">${afterSlash}</span>
							</p>
							</div>`;
				}
		
				return `<div class="format_carat mb-0">
					<p class="mb-0 digit"> ${removeCt}</p>
				</div>`;
			}
		}
		
	}
})
