<template>
    <form @submit.prevent="Login">
        <div class="row">
            <div class="col-md-12 pb-3" v-if="errors">
                <span class="error">{{ errors }}</span>
            </div>
            <div class="col-md-12 pb-3 select_block_mod">
                <label class="mb-1">Email Id</label>
                <input name="email" autocomplete="off" required placeholder="" type="email" v-model="formData.email" class="" />
            </div>
            <div class="col-md-12">
                <CommonBaseInputPassword name="password" value="" required mode="" placeholder="Password" v-model="formData.password" />
            </div>
            <div class="col-md-12 text-end py-2"><nuxt-link v-if="!['address', 'cart'].includes(page)" :to="localePath('/forgot-password')">Forgot your password?</nuxt-link></div>
            <div class="col-md-12 pt-2">
                <button disabled v-if="showLoader">please wait...</button>
                <button v-else type="submit">login</button>
            </div>
            <div class="col-md-12 text-center mt-3" v-if="toomany_req_error">
                <span class="error">{{ toomany_req_error }}</span>
            </div>
        </div>
    </form>
  </template>
  

<script setup>
    import setCsrfToken from '~/composables/csrf/setCsrfToken';
    import { cartStore } from '@/stores/cart/cart';

    const { page } = defineProps(['page'])
    const localePath = useLocalePath()
    const { login, logout } = useSanctumAuth();
    const router = useRouter()
    const route = useRoute()
    // const route = useRoute()
    const {  $toast, $i18n } = useNuxtApp()
    const store = cartStore()


    const showLoader = ref(false)
    const formData = ref({
        email: '',
        password: '',
        remember: true
    })
    const errors = ref(null)
    const toomany_req_error = ref(false)

    async function Login(){
        showLoader.value = true;
        try {
            
            await setCsrfToken()
            await login({...formData.value,store: $i18n.locale.value})
            .then(()=>{

                let routes = ['login', 'register', 'forgot-password', 'reset-password']
    
                $toast('success', 'Congratulations!', 'You are successfully logged in.')
                store.setCartCount()
                if (routes.some(rt => route.name.includes(rt))) {
                    router.push(localePath({ name: 'account-detail' }));
                }else{
                    window.location.reload()
                }
    
                // if (page  === 'cart') {
                //     // check if condition getting true
                //     router.push(localePath({ path: 'cart' }));
                // }
                // else if (page === 'header') {
                //    return window.location.reload()
                // }
                // else if (page === 'address') {
                //     router.push(localePath({ path: 'checkout/address' }));
                // }
                // else{
                //     router.push(localePath({ name: 'account-detail' }));
                // }
            })


        } catch (error) {
            showLoader.value = false;
            if (error  && error.response?.status === 422) {
                errors.value = 'These credentials do not match our records.'
                return;
            }
            if (error && error.response?.status === 429) {
                toomany_req_error.value = "Too many requests, please try again after 60 seconds"
            }
            throw error
        }
    }
  
// export default {


//     methods: {
        
//         login() {

//             this.showLoader = true;

//             this.errors = null
//             this.toomany_req_error = false

//             this.formData = { ...this.formData, store : this.$i18n.locale }
            
//             const res = this.$auth.loginWith('laravelSanctum', { data: this.formData })
//                 .then((res) => {

                    
//                     this.showLoader = false;
                    

//                     this.$store.dispatch('getCartCount');
                    
//                     this.$toast('success', 'Congratulations!', 'You are successfully logged in.')

//                     let routes = ['login', 'register', 'forgot-password', 'reset-password']

//                     if (routes.some(route => this.$route.name.includes(route))) {
//                         this.$router.push(this.localePath({ name: 'account-detail' }));
//                     }

//                     if( res.data.message === 'Already logged in' ){

//                         window.location.reload()
//                     }
                   

//                 })
//                 .catch((error)=>{

//                     this.showLoader = false;
//                     if ( error.response?.status === 422) {
//                         // this.errors = error?.response?.data?.errors
//                         this.errors = 'These credentials do not match our records.'
//                         return;
//                     }

//                     if ( error.response?.status === 429) {
//                         this.toomany_req_error = "Too many requests, please try again after 60 seconds"
//                     }
                   
//                 });
//         } 
//     },
// }
</script>

<style scoped>
input {
    border-radius: 4px ;
}

button {
    border-radius: 4px;
}
.user_block {
    margin-top: 6%;
}

span.error{
    color: rgba(255, 0, 0, 0.936);
}

a{
    color: #70c099;
    font-size: 14px;
}
h1.title {
    font-family: "NY600";
    font-size: 52px;
    line-height: normal;
}
.select_block_mod label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
}

.select_block_mod input {
    font-size: 16px;
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
}
.title_box {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 3%;
    margin-bottom: 6%;
}
button{
    background: var(--green) !important;
    border-color: none;
    text-transform: uppercase;
    font-size: 14px !important;
    border: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    color: #fff;
    width: 100%;
    min-height: 46px;
}
@media(max-width: 767px){
    h1.title{
        font-size: 30px;
    }
    .register_cta{
        text-align: left;
    }
    .user_block {
        /* margin-top: 20%; */
    }
}
</style>
