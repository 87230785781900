export default defineNuxtPlugin((nuxtApp) => {

  const { isCrawler } = useDevice()
  if( isCrawler ){

      return
  }
  
  const config = useRuntimeConfig()

  if (config.public.enviroment == 'production') {

    if( import.meta.server ){

      return
    }


    useHead({
      script: [
        {
          src: `https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz`,
          // defer:true,
          tagPosition: "head",
        },
        {
          src: `https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.22-min.js.gz`,
          // defer:true,
          tagPosition: "head",
        },   
      ],
    });

    nuxtApp.hook('app:mounted', () => {

      let amplitudeInitialized = false; // Flag to prevent reinitialization

      let tries = 1;
      const code = '6073cdd26e43933f2939e5a468f65551'

      // Initialize Amplitude once scripts are loaded
      const initAmplitude = () => {

        if (!amplitudeInitialized && window.amplitude && window.sessionReplay) {
          amplitudeInitialized = true; // Prevent further initializations
          window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
          window.amplitude.init(code, {
            autocapture: {
              elementInteractions: true,
            },
          });
        } else {
          console.error("Amplitude not loaded");
        }
      };

      // Poll for the Amplitude library
      const checkAmplitude = setInterval(() => {

        tries++
        if(tries > 10  ){

          clearInterval(checkAmplitude);
          console.warn("Amplitude scripts failed to load after 5 attempts.");

          return
        }

        if (window.amplitude && window.sessionReplay) {
          initAmplitude();
          clearInterval(checkAmplitude); // Stop checking once initialized
        }
      }, 500); // Check every 500 ms
                
    })
  }

});
  