
export default async function setCsrfToken(  ) {

	const COOKIE_OPTIONS = { readonly: true };

	const app = useNuxtApp()
    
    const config = app.$config.public.sanctum
	

	async function initCsrfCookie(config) {
		await $fetch(config.endpoints.csrf, {
		  baseURL: config.baseUrl,
		  credentials: "include"
		});
	}

	let csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
	if (!csrfToken.value) {
		await initCsrfCookie(config);
		csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
	}
}