<template>
    <li>
        <div class="my_account">
            <div class="toggle_user">
                <NuxtLink :to="localePath('/login')" v-if="!isAuthenticated">
                    <SvgHeaderSvg :type="'user'" />
                </NuxtLink>
                <NuxtLink :to="localePath('/account/detail')" v-if="isAuthenticated">
                    <SvgHeaderSvg :type="'user'" />
                </NuxtLink>
            </div>
            <div class="user_drop_down user_drop_down_mod" v-if="isAuthenticated">
                <div class="logged_in">
                    <ul class="mb-0">
                        <li @click="accountCta('/account/detail')">Personal Details</li>
                        <li @click="accountCta('/account/orders')">My Orders</li>
                        <li @click="logOut()">logout</li>
                    </ul>
                </div>
            </div>
            <div class="user_drop_down " v-if="!isAuthenticated">
                <div class="logged_out">
                    <div class="title_close">
                        <h3 class="mb-0 font-active">Login</h3>
                    </div>
                    <div class="login_block">
                        <AuthTheLogin :page="'header'" />
                    </div>
                    <div class="register">
                        <p>Don’t have an account?</p>
                        <NuxtLink :to="localePath('/register')">sign up</NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup>
    import setCsrfToken from '~/composables/csrf/setCsrfToken';
    import { cartStore } from '@/stores/cart/cart';
    const store = cartStore()

    const router = useRouter()
    const route = useRoute()

    const { logout, isAuthenticated  } = useSanctumAuth();
    const user = useSanctumUser();
    const localePath = useLocalePath()
    const props = defineProps(['link'])

    const accountCta = (link) => {
        router.push(localePath(link))
    }

    const logOut = async () => {
        await setCsrfToken()
        await logout()
        await store.setCartCount()
       
        const routes = ['account-change-password', 'account-detail', 'account-orders', 'account-orders', 'account-wishlist', 'account-orders-id'];
        if (routes.some(rt => route.name.includes(rt))) {
            router.push(localePath("/"));
        }

    };
</script>

<style scoped>

.my_account {
    position: relative;
}
.toggle_user{
    cursor: pointer;
}
.user_drop_down {
    position: absolute;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.1);
    right: -60px;
    width: 340px;
    top: 100%;
    z-index: 999;
    background: #fff;
    border-radius: 8px;
    z-index: 9999999;
}
.user_drop_down.user_drop_down_mod {
    width: 200px;
}
.toggle_user a {
    color: #000;
}
.close_user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close_user span{
    cursor: pointer;
}

.title {
    font-size: 20px;
    color: #212529;
}

.user_drop_down ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.user_drop_down ul li {
    margin-left: 0 !important;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
    cursor: pointer;
    text-transform: capitalize;
    list-style: none;
    border: none;
}
h3 {
    font-size: 20px;
}

.title_close {
    padding: 19px 25px;
    border-bottom: 1px solid #e2e2e2;
}
.logged_in{
    padding: 19px 25px;
}
.login_block{
    padding: 19px 25px;
}
.register {
    margin: 0 30px 19px;
    border-top: 1px solid #e2e2e2;
    padding-top: 10px;
}

.register p {
    text-align: center;
    color: #212529;
    font-size: 14px;
}

.register a {
    font-weight: 600;
    border: 1px solid #000;
    padding: 11px 25px;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    color: #000;
    border-radius: 4px;
}
.user_drop_down {
    display: none;
}

.my_account:hover .user_drop_down {
    display: block;
}


@media(max-width: 1800px){
    .user_drop_down{
        right: -40px;
    }
}
</style>
