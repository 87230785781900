
<template>
    <svg  class="svg-footer" v-html="svgIcon">
    </svg>
</template>

<script setup>
    const props = defineProps(['type']);
    const headerSvgs = ['instagram', 'facebook', 'twitter', 'linkedin', 'youtube', 'pinterest', 'compare', 'compare_active']
    
    const svgIcon = computed(() => {
        if (headerSvgs.includes(props.type)) {
            return `<use xlink:href="/svg/footer.svg#${props.type}"> </use>`;
        } 
    });
</script>

<style scoped>
/*span.svg-parent {
    display: flex;
    align-items: center;
    justify-content: center;
}*/
</style>
