import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const cartStore = defineStore('cart', {
    state: () => {
        return {
            cartCount: 0,
            cartData: [],
            cartTotal: {},
            currency: 'USD'
        }
    },
    getters: { ...getters },
    actions: {
        ...actions, commitMutation: function (mutationToCall, data) {

            try {
                mutations[mutationToCall](this, data)
            } catch (error) {
                console.error('CART MUTATION ERROR - ', mutationToCall, data, error)
                throw error
            }
        }
    },
    // persist: true,
})
