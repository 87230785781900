<template>
    <div id="global_main" class="user_search" tabindex="0" @focusout="handleFocusOut($event)">
        <form @submit.prevent="Submit" :class="{ 'opened': openSearch }">
            <div class="search_form" :class="{ 'show': openSearch, 'hide': !openSearch }">
                <input class="for_desktop" minlength="2" ref="search_input" required @input="searchOption"
                    placeholder="Search" v-model="inputValue" type="text">
                <input class="for_mobile" minlength="2" required @input="searchOption" @focusin="focusInput"
                    placeholder="Search" v-model="inputValue" type="text">
                <div v-if="!openSearch" class="search_icon" @click="clickSearch">
                    <SvgHeaderSvg :type="'search'" />
                </div>
                <button v-else type="submit">
                    <SvgHeaderSvg :type="'search'" />
                </button>
            </div>
        </form>

        <div v-if="openMainSearch && openSearch">
            <div class="search_result">
                <div class="pages_list" v-if="searchList.pages.length">
                    <div>
                        <h3 class="mb-0">Suggestions</h3>
                        <ul>
                            <li v-for="list in searchList.pages" :key="list.slug">
                                <NuxtLink @click="closeGlobalSearch" :to="localePath(list.slug)">{{ list.title }}</NuxtLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="searchList.products.length">
                    <h3 class="">Products</h3>
                    <div class="search_list">
                        <NuxtLink @click="closeGlobalSearch" :to="localePath(list.slug)" v-for="list in searchList.products" :key="list.id">
                            <div class="list_box">
                                <div class="list_img">
                                    <img width="50" height="50" :src="list.image" />
                                </div>
                                <div class="list_name">{{ list.title }}</div>
                                <div class="list_price">{{ $formatPrice(list.price) }}</div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchList.blogs.length">
                    <h3 class="">Blogs</h3>
                    <div class="search_list search_list_mod">
                        <NuxtLink @click="closeGlobalSearch" :to="localePath(list.slug)" v-for="list in searchList.blogs" :key="list.slug">
                            <div class="list_box">
                                <div class="list_img">
                                    <img width="50" height="50" :src="list.image" />
                                </div>
                                <div class="list_name">{{ list.title }}</div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>

                <div class="my-4 mx-2" v-if="errorMsg">
                    <p class="mb-0 text-center">{{ errorMsg.join(" ") }}</p>
                </div>
                <div class="loader text-center" v-if="loader">
                    <img width="246" class="loader_img" height="246" alt="loader" src="~/assets/assets/loader.svg" />
                </div>
                <div class="my-4 mx-2" v-if="ifSearchLength && !loader && inputValue" >
                    <p class="mb-0 text-center">No result found for <strong>" {{ inputValue }} "</strong></p>
                </div>
                <div class="goto_search_page" v-if="!ifSearchLength">
                    <NuxtLink @click="closeGlobalSearch" :to="localePath('/search/?search=' + inputValue)">View All</NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import debounce from 'debounce';



const app = useNuxtApp()
const router = useRouter();
const route = useRoute();
const localePath = useLocalePath()
const { $country, $i18n } = useNuxtApp()

const openSearch = ref(false);
const openMainSearch = ref(false);
const inputValue = ref('');
const searchList = ref({
    pages: [],
    products: [],
    blogs: []
});

const search_input = ref(null)

const serverCacheKey = `GlobalSearch::${$i18n.locale}`

const loader = ref(false);
const errorMsg = ref(false);

const searchInput = ref(null);

watch(route, (to, from) => {
    if (to.path !== from.path) {
        openMainSearch.value = false;
        openSearch.value = false;
    }
});

const closeGlobalSearch = ()=>{
    openMainSearch.value = false;
    openSearch.value = false;
    inputValue.value = ''
}


onMounted(() => {
    document.addEventListener('click', (event) => {
        if (!event.target.closest('#global_main')) {
            openMainSearch.value = false;
        }
    });
})

const ifSearchLength = computed(() => {
   
    if (
        searchList.value.pages.length ||
        searchList.value.products.length ||
        searchList.value.blogs.length
    ) {
        return false
    }
    return true
});

function clearListing() {
    searchList.value.pages = [];
    searchList.value.products = [];
    searchList.value.blogs = [];
}

function focusInput() {
    openSearch.value = true;
}

function handleFocusOut(event) {
    if (event.target.id === 'global_main') {
        openMainSearch.value = false;
    }
}

function clickSearch() {
    clearListing();
    inputValue.value = '';
    openSearch.value = !openSearch.value;
    openMainSearch.value = true;
    search_input.value.focus()
}

function Submit() {
    if (inputValue.value.trim().length > 1) {
        openSearch.value = !openSearch.value;
        openMainSearch.value = false;
        router.push(localePath({ path: '/search', query: { search: inputValue.value } }));
    }
}

async function getResult() {
    const trimmedVal = inputValue.value.trim();
    if (trimmedVal.length > 1) {
        errorMsg.value = false;
        clearListing();
        loader.value = true;
        try {
            const res = await $fetch(useCountryPath(`/search?search=${inputValue.value}&perPage=6`));
            if (res?.success) {
                searchList.value.pages = res.data.pages.hits;
                searchList.value.products = res.data.products.hits;
                searchList.value.blogs = res.data.blogs.hits;
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                errorMsg.value = error.response._data.errors.search;
            }
        } finally {
            loader.value = false;
        }
    }
}


const searchOption = debounce(function (e) {
    getResult()
    openMainSearch.value = true
}, 1000);

</script>


<style scoped>
h3 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 15px 0 15px;
}

.user_search {
    margin-top: 4px;
    height: 32px;
    display: flex;
    align-items: center;
}

.search_result {
    max-height: 500px;
    overflow: auto;
}

form {
    display: flex;
    align-items: center;
}

.search_form {
    display: flex;
    align-items: center;
    height: 32px;
}

.search_form input {
    border: 0;
    outline: none;
    height: 30px;
    transition: 0.5s;
    padding-right: 10px;

}

.search_form.show input {
    width: 250px;
    transition: 0.5s;
}

.search_form.show {
    border-bottom: 1px solid #ccc;
}

.search_form {
    border-bottom: 1px solid transparent;
}

.search_form.hide input {
    width: 0;
    transition: 0.5s;
}

.search_form input,
.search_form input::placeholder {
    font-size: 14px;
    color: #606060;
}

.close_icon {
    height: 27px;
    cursor: pointer;
}

.search_icon {
    height: 21px;
    cursor: pointer;
}

/* --- form result --- */
.user_search {
    position: relative;
}

.search_result {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, .1);
    padding: 0;
    position: absolute;
    left: 0;
    top: 100%;
    width: 270px;
    z-index: 99999999;
}

.list_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 15px;
}

.list_img {
    width: 20%;
}

.list_name {
    width: 60%;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 0 10px;
    color: #000;
}

.search_list_mod .list_name {
    width: 80%;
}

.list_price {
    width: 20%;
    color: #000;
    font-size: 14px;
    text-align: right;
    font-weight: 600;
}

.goto_search_page {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.goto_search_page a {
    border: 1px solid var(--green);
    font-size: 14px;
    min-width: 110px;
    text-align: center;
    padding: 6px;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 600;
    color: var(--green) !important;
}

button {
    height: 21px;
    cursor: pointer;
    border: 0;
    padding: 0;
    background: #fff;
}

.pages_list ul {
    margin: 5px 0;
    padding: 0;
    display: block !important;
}

.pages_list ul li {
    list-style: none;
    margin: 0;
}

.pages_list ul li a {
    padding: 3px 15px;
    display: inline-block;
    text-transform: capitalize;
    color: #000;
    font-size: 14px;
}

/* --- form result --- */
@media(max-width: 1110px) {
    .search_form input {
        padding-right: 0px;
        font-size: 16px;
    }

    .search_form.show input{
        width: 110px;
    }
    .search_result{
        width: 220px;
    }
}

@media(max-width: 767px) {
    form {
        background: #fff;
        width: 100%;
        display: block;
    }

    .search_form {
        height: 35px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px 5px 10px 10px;
    }

    .search_form input {
        height: 28px;
        width: 100%;
        padding-right: 0;
    }

    .user_search {
        margin-top: 0;
        height: auto;
        padding: 0 3px 12px 3px;
    }

    .search_result {
        width: 100%;
    }

    .search_form.hide input,
    .search_form.show input {
        width: 100%;
    }
}
</style>
