<template>
    <div style="position:relative">
      <label class="mb-1">{{ placeholder }}</label>
      <input v-if="showPassword" 
            :id="id"
            :name="name"
            :class="['', mode]"
            v-model="model"
            required
            :maxlength="maxlength"
            type="text"
            @input="emitUpdates"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
        >
        <input v-else 
            :id="id"
            :name="name"
            :class="['', mode]"
            required
            v-model="model"
            :maxlength="maxlength"
            type="password"
            @input="emitUpdates"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
        >
        <div class="eye_block" v-if="showPassword" @click="showPassword = false">
            <SvgHeaderSvg :type="'eye-open'" />
        </div>
        <div class="eye_block" v-else @click="showPassword = true">
            <SvgHeaderSvg :type="'eye-close'" />
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        id: String,
        mode: String,
        name: String,
        placeholder: String,
        value: String,
        maxlength: Number
    })
  
    const emit = defineEmits(['input'])
    
    const showPassword = ref(false)
    const model = ref(props.value)
    
    const emitUpdates = (event) => {
        emit('input', event.target.value)
    }
    
    watch(model, (currentValue) => {
        emit('update:modelValue', currentValue)
    })
</script>
  

  
<style scoped>
  label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
    text-transform: capitalize;
  }
  input.invalid {
    border-color: #fc7979 !important;
  }
  input {
    font-size: 16px;
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
    border-radius: 4px;
  }
  input::placeholder {
    font-size: 14px;
    color: #363030;
  }
  .eye_block {
    cursor: pointer;
    position: absolute;
    bottom: -2px;
    right: 0;
    transform: translate(-50%, -50%);
  }
  </style>
  