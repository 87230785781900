<template>
    <div>
        <CommonTheHeader />
        <div class="container">
            <div class="error_flex">
                <div class="error_box" v-if="error.statusCode === 404">
                    <img width="200" height="200" src="https://assets.friendlydiamonds.com/fdl/fd_gif/404.gif">
                    <!-- <h2>404</h2> -->
                    <h3>oops!</h3>
                    <p>We can’t seem to find the page you’re looking for.</p>
                </div>
                <div class="error_box" v-if="error.statusCode === 429">
                    <img width="200" height="200" src="https://assets.friendlydiamonds.com/fdl/fd_gif/500.gif">
                    <h3>oops!</h3>
                    <p>Error 429: TOO MANY REQUESTS</p>
                </div>
                <div class="error_box" v-if="error.statusCode === 500">
                    <img width="200" height="200" src="https://assets.friendlydiamonds.com/fdl/fd_gif/500.gif">
                    <h3>500 Internal Server Error</h3>
                    <p>We apologize for the inconvenience. Please refresh the page or try again later. If you continue to experience this issue, <router-link to="/contact-us">please contact support!</router-link></p>
                </div>
                <div v-if="error.statusCode === 1002">
                    <div class="">
                        <div class="networjError  mx-auto mb-4">
                            <h3 class="mb-3"> Something went wrong</h3>
                            <p> Cart Creation Failure: {{ $t('networkError') }} </p>
                            
                        </div>
                        <div class="w-25 mx-auto">
                            <!-- <ButtonPrimary @emit-event="reload">Reload</ButtonPrimary> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="error.statusCode === 404">
            <!-- <ShopByCategory :page="'404'" /> -->
        </div>
        <CommonTheFooter />
    </div>
</template>

<script setup>
    const {error} = defineProps(['error'])

    const route = useRoute()
    const queryParams = route.query
    
    // const data = await $fetch(useCountryPath('/redirect?path='+route.path))
    // if (data.data.url?.new_url) {    
    //     let url = data.data?.url.new_url
    //     if(queryParams){
    //         const query = Object.entries(queryParams).map(([key,value]) => `${key}=${value}`).join('&');
    //         url = `${data.data?.url.new_url}?${query}`
    //     }
    //     navigateTo(url, 302)

    // }

</script>


<style scoped>
.error_flex {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error_box h2 {
    font-size: 7rem;
    font-weight: 200;
    color: #10312a;
}

.error_box h3 {
    text-transform: uppercase;
    font-weight: 500;
    padding: 1% 0 2%;
    color: #10312a;
}

.error_box p {
    font-size: 18px;
}

.error_box p a {
    text-decoration: underline;
    color: #10312a;
}
.networjError{
    max-width: 80%;
}

@media(max-width:767px){
    .networjError{
        max-width: 100%;
    }
    .error_flex {
        padding: 2% 5%;
    }

    .error_box h2 {
        font-size: 4rem;
    }

    .error_box h3 {
        font-size: 1.5rem;
    }

    .error_box p {
        font-size: 14px;
    }

}
</style>


