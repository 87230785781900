
<template>
    <svg class="svg-menu" v-html="svgIcon">
    </svg>
</template>

<script setup>
const props = defineProps(['type'])

const headerSvgs = [
    'blockchain', 'cyoe', 'cyop', 'cyor', 'diamond', 
    'cyor_setting', 'user_bg', 'usa'
]

const svgIcon = computed(()=>{
    if(headerSvgs.includes(props.type)){
        return `<use xlink:href="/svg/menu.svg#${props.type}"> </use>`
    }
})

</script>
<style scoped>

</style>
