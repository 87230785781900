import type { FetchContext } from 'ofetch'
import type { ConsolaInstance } from 'consola'
import type { NuxtApp } from '#app'
import setCsrfToken from '~/composables/csrf/setCsrfToken';

export default defineAppConfig({
    sanctum: {
        interceptors: {
            onRequest: async (
                app: NuxtApp,
                ctx: FetchContext,
                logger: ConsolaInstance
            ) => {

                // if( import.meta.client ){

                //     console.log('ctz', ctx)
                // }

                // console.log('import.meta.server && ctx.request === api/user', import.meta.server, ctx.request , ctx.request === '/api/user')

                // if( import.meta.server && ctx.request === '/api/user' ){

                //     console.log('ctxrequest',ctx.request )

                //     throw new  Error('Rejected request')
                //     return
                // }

                // console.log('ctxrequest-----------------------------complete',ctx.request )
            },

            onResponse: async (
                app: NuxtApp,
                ctx: FetchContext,
                logger: ConsolaInstance
            ) => {
                logger.debug(`2222222222222222222222222222222222222222222 onResponse interceptor (${ctx.request})`);
            },
        },
    },
});
