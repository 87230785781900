<template>
    <div class="logo_box">
        <router-link class="font-active" :to="localePath('/')">Friendly Diamonds</router-link>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp() 
</script>

<style scoped>
.logo_box {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo_box a {
    font-size: 30px;
    display: block;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: normal;
    color: #000;
}
.logo_box a:hover, .logo_box a:focus{
    text-decoration: none;
}

@media(max-width: 1024px){
    .logo_box {
        width: 50%;
    }
    .logo_box a {
        font-size: 24px;
    }
}

@media(max-width: 767px){
    .logo_box {
        width: 60%;
    }
    .logo_box a {
        font-size: 18px;
    }
}
</style>
