import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_c1UCGf85vx from "/vercel/path0/modules/m/device-main/runtime/plugin.ts";
import plugin_zCk5SvrVBJ from "/vercel/path0/modules/m/nuxt-auth-sanctum-main/runtime/plugin.ts";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import customFetch_5XQo2Q3J4W from "/vercel/path0/plugins/customFetch.js";
import cart_KnoRTcAkJx from "/vercel/path0/plugins/cart.js";
import currency_Gx1n4rkgt4 from "/vercel/path0/plugins/currency.js";
import google_analytics_client_vKtiHLcz65 from "/vercel/path0/plugins/google-analytics.client.js";
import toast_Zl2rZYKMaV from "/vercel/path0/plugins/toast.js";
import thirdparty_pAEkZSy0qS from "/vercel/path0/plugins/thirdparty.js";
import format_carat_WajAggjUtR from "/vercel/path0/plugins/format-carat.js";
import locationRedirect_LdUSgLXmHl from "/vercel/path0/plugins/locationRedirect.js";
import route_client_dD2t47vhQH from "/vercel/path0/plugins/route.client.js";
import amplitude_qcoZpE5aTU from "/vercel/path0/plugins/amplitude.ts";
import commonHeader_Ft7nWBOniT from "/vercel/path0/plugins/commonHeader.js";
import modalCleanup_client_IBebL9vlIR from "/vercel/path0/plugins/modalCleanup.client.js";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import vue_tel_input_GukcJ5wu6r from "/vercel/path0/plugins/vue-tel-input.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_c1UCGf85vx,
  plugin_zCk5SvrVBJ,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  customFetch_5XQo2Q3J4W,
  cart_KnoRTcAkJx,
  currency_Gx1n4rkgt4,
  google_analytics_client_vKtiHLcz65,
  toast_Zl2rZYKMaV,
  thirdparty_pAEkZSy0qS,
  format_carat_WajAggjUtR,
  locationRedirect_LdUSgLXmHl,
  route_client_dD2t47vhQH,
  amplitude_qcoZpE5aTU,
  commonHeader_Ft7nWBOniT,
  modalCleanup_client_IBebL9vlIR,
  sentry_3AyO8nEfhE,
  vue_tel_input_GukcJ5wu6r
]