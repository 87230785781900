import { createApp, h } from 'vue';
import Toast from '@/components/common/Toast.vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('toast', (variant, title, message) => {
    // Create a new app for the Toast component
    const toastApp = createApp({
      render: () =>
        h(Toast, {
          variant,
          title,
          message,
        }),
    });

    // Mount the app to a new element
    const mountPoint = document.createElement('div');
    document.body.appendChild(mountPoint);
    toastApp.mount(mountPoint);

    // Automatically unmount after the toast is removed
    setTimeout(() => {
      toastApp.unmount();
      document.body.removeChild(mountPoint);
    }, 3000); // Match the timeout with your Toast component's closing logic
  });
});
