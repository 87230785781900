import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {

    // for listing where we update query params
    const diamondDetailPagePaths = ['loose-detail', 'cyo-diamond-detail-ring', 'cyo-diamond-detail-pendant', 'cyo-diamond-detail-earring']
    if (to.name === from.name && !diamondDetailPagePaths.includes( to?.meta?.name ?? '' )  ) {
      return false; // Disable scroll for specific routes
    }
    if (savedPosition) {

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({...savedPosition, behavior: 'auto',});
        }, 600);
      });

      return {...savedPosition, behavior: 'instant',}
    } else {
      return { top: 0, left: 0 }
    }

    // return false

    // return { top: 0 }; // Always scroll to the top for every route

    return { top: 0, left: 0 };


    // scroll to hash, useful for using to="#some-id" in NuxtLink
    // ex: <NuxtLink to="#top"> To Top </ NuxtLink>
    if (to.hash) {
      console.log('to.hash: ', to.hash);
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    // The remainder is optional but maybe useful as well

    // if link is to same page, scroll to top with smooth behavior
    if (to === from) {
      return {
        left: 0,
        top: 0,
        behavior: 'instant',
      };
    }

    // this will use saved scroll position on browser forward/back navigation
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          left: savedPosition?.left || 0,
          top: savedPosition?.top || 0,
        });
      }, 500);
    });
  },
};
