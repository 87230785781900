import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const addressStore = defineStore('address', {
    state: () => {
        return {
            cartData: [],
            userLocation: null,
            address_id: false,
            guestData:{
                first_name:'',
                last_name:'',
                email:'',
                password:'',
                confirm_password:'',
                is_guest:1
            },
            addressData: {
                billing_address: '',
                billing_address_two: '',
                billing_city: '',
                billing_country: '',
                billing_country_short: '',
                billing_first_name: '',
                billing_last_name: '',
                billing_phone: '',
                billing_postcode: '',
                billing_same: 1,
                billing_state: '',
                order_note: null,
                shipping_address: '',
                shipping_address_two: '',
                shipping_city: '',
                shipping_country: '',
                shipping_country_short: '',
                shipping_first_name: '',
                shipping_last_name: '',
                shipping_phone: '',
                shipping_postcode: '',
                shipping_state: '',
            },
            errorData: {}
        }
    },
    getters: { ...getters},
    actions: {...actions, commitMutation: function( mutationToCall, data ) {

        try {
            mutations[mutationToCall]( this, data )
        } catch (error) {
            console.error( 'ADDRESS MUTATION ERROR - ', mutationToCall, data, error )
            throw error
        }
    }},
    // persist: true,
})
