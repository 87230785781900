<template>
    <div class="hamburger_block">
        <div class="hamburger_flex">
            <div class="hamburger_box" @click="closeMenu">
                <SvgHeaderSvg :type="'hamburger'" />
            </div>
            <div class="logo_box">
                <img src="~/assets/assets/logo.svg" width="28" height="28" alt="friendly logo">
                <p class="mb-0 font-active">friendly diamonds</p>
            </div>
        </div>
        <div class="sign_block">
            <div class="sign_box">
                <ClientOnly>
                    <SvgMenuSvg :type="'user_bg'" />
                <router-link @click="$emit('hideShowMenu')" v-if="!isAuthenticated" :to="localePath('/login')">Login / Sign Up</router-link>
                <router-link @click="$emit('hideShowMenu')" v-else :to="localePath('/account/detail')">{{ user.first_name }} {{ user.last_name }}</router-link>
                </ClientOnly>
            </div>
            <div class="currency_box" v-if="$country.currency_usd">
                <p class="mb-0">USD</p>
                <img src="~/assets/assets/usa.svg" width="28" height="28" alt="usd logo">
            </div>
            <div v-else class="currency_box">
                <p class="mb-0">INR</p>
                <img src="~/assets/assets/india.svg" width="28" height="28" alt="usd logo">
            </div>
        </div>
    </div>
</template>

<script setup>
    import setCsrfToken from '~/composables/csrf/setCsrfToken';


    const localePath = useLocalePath()
    const { logout, isAuthenticated  } = useSanctumAuth();
    const user = useSanctumUser();
    async function loggedout(){
        await setCsrfToken()
        await logout()
    }
    const emit = defineEmits(['closeMenu','hideShowMenu'])
    
    const closeMenu = () => {
    emit('closeMenu')
    }
</script>

<style scoped>
.hamburger_block {
    border-bottom: 1px solid #eaeaea;
}
.hamburger_flex {
    display: flex;
    align-items: center;
    padding: 18px 18px 18px 18px;
}

.logo_box {
    display: flex;
    align-items: center;
}

.logo_box p {
    font-size: 18px;
    text-transform: uppercase;
    margin-left: 5px;
}

.hamburger_box {
    height: 24px;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
}


.sign_block {
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
    padding: 15px 0;
}

.currency_box {
    display: flex;
    align-items: center;
}

.currency_box p {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
}

.sign_box a {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
}
</style>

