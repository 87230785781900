export default {

    fetchCartCount: (state) => () => {
        return state.cartCount;
    },

    getCartListing: (state) => () => {
        return state.cartData.items;
    },
    getCartCouponData: (state) => () => {
        return state.cartData.coupon;
    },
    getCartTotal: (state) => () => {
        return state.cartTotal;
    },

    getCurrency: (state) => () => {
        return state.currency;
    },

}