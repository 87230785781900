// plugins/add-schema.ts
import { useHead } from '@unhead/vue'

export default defineNuxtPlugin((nuxtApp) => {

    return
  nuxtApp.hooks.hook('app:created', () => {

    const route = useRoute()

    const IndiaHeaders = {
    
        htmlAttrs: {
            lang: 'en-IN'
        },
  
        script: [{ 
            type: 'application/ld+json', 
          
            children: JSON.stringify({
                "@context":"http://schema.org",
                "@type":"Organization",
                "name":"Friendly Diamonds India",
                "url":"https://friendlydiamonds.com/in/",
                "logo":"https://assets.friendlydiamonds.com/fdl/v2/images/logo.svg",
                "brand":"Friendly Diamonds",
                "location":[
                    {
                        "@type":"PostalAddress",
                        "streetAddress":"Salasar Estate, Ground Floor, 1-8 Yadav Nagar, Dahisar East",
                        "addressLocality":"Mumbai",
                        "addressRegion":" ",
                        "postalCode":"400068"
                    }
                ],
                "telephone":"",
                "sameAs":[
                    "https://www.instagram.com/friendlydiamondsindia"
                ]
            }) 
        }]
    };

    const USHeaders = {
        htmlAttrs: {
            lang: 'en-US'
        },
      
        script: [{ 
            type: 'application/ld+json', 
            children: JSON.stringify({
                "@context":"http://schema.org",
                "@type":"Organization",
                "name":"Friendly Diamonds",
                "url":"https://friendlydiamonds.com/",
                "logo":"https://assets.friendlydiamonds.com/fdl/v2/images/logo.svg",
                "brand":"Friendly Diamonds",
                "location": [{
                    "@type": "PostalAddress",
                    "streetAddress": "20 W 47th St, Suite #716",
                    "addressLocality": "New York",
                    "addressRegion": "NY",
                    "postalCode": "10036"
                }],
                "telephone": "1 646 956 4572",
                "sameAs":[
                    "https://www.facebook.com/FriendlyDiamonds",
                    "https://twitter.com/Friendldiamonds",
                    "https://www.instagram.com/friendly.diamonds",
                    "https://www.pinterest.com/friendly_diamonds",
                    "https://www.youtube.com/@friendly.diamonds"
                ]
            }) 
        }]
    };

    let commonHeaders
    if(nuxtApp.$country.location === 'IN'){
        if(['/in', '/in/'].includes(route.path)){
            IndiaHeaders.script.push({
                type: 'application/ld+json', 
                json: {
                    "@context": "http://schema.org",
                    "@id": "https://friendlydiamonds.com/in/#webpage",
                    "@type": "WebPage",
                    "url": "https://friendlydiamonds.com/in/",
                    "name": "Friendly Diamonds India"
                }
            })
        }
       
        commonHeaders = IndiaHeaders
    }else{
        if(route.path === '/'){
            USHeaders.script.push({
                type: 'application/ld+json', 
                json: {
                    "@context": "http://schema.org",
                    "@id": "https://friendlydiamonds.com/#webpage",
                    "@type": "WebPage",
                    "url": "https://friendlydiamonds.com/",
                    "name": "Friendly Diamonds"
                }
            })
        }
        commonHeaders = USHeaders
    }

    useHead(commonHeaders)

    })

  })

