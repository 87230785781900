export default defineNuxtPlugin(() => {


    const destroy = (to, from) => {
        
        const filter_selectors = document.querySelectorAll('body.filter_open');
        if(filter_selectors && filter_selectors.length && to.name != from.name){
            document.body.classList.remove('filter_open');
        }
        if(filter_selectors && filter_selectors.length && to.fullPath != from.fullPath){
            document.body.classList.remove('filter_open');
        }

        const selectors = document.querySelectorAll('.modal.fade');

        if( selectors && to.fullPath != from.fullPath ){
            
            let discardBackdrop = false
            if( selectors && selectors.length ){
                
                for (const modalId of selectors) {
                    
                    const modalInstance = bootstrap.Modal.getInstance(modalId);
                    if (modalInstance && typeof modalInstance.dispose === 'function') {
                        modalInstance.dispose();
                        discardBackdrop  = true
                    }
                }
    
    
                if( discardBackdrop ){
                    
                    //Check if 'modal-open' class exists on the body and remove it if present
                    if (document.body.classList.contains('modal-open')) {
                        document.body.classList.remove('modal-open');
                        document.body.removeAttribute('style');
                    }
        
                    // Check if any elements with the class 'modal-backdrop' exist and remove them
                    const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                    if (modalBackdrops.length > 0) {
                        modalBackdrops.forEach(function(modalBackdrop) {
                            if( typeof modalBackdrop.remove === 'function' ){
                                
                                modalBackdrop.remove();
                            }
                        });
                    }
                }
            }
        }



    }

    const modalCleaup = async ()=>{
        const router = useRouter()

        //if bottom sheet is open on route change remove overflow from body
        
        router.beforeEach((to, from, next) => {

            destroy(to, from)
            
            next();
        });        
    }
    
    addRouteMiddleware( async (to, from) => {

        if( import.meta.client ){

            modalCleaup()
            return
        }

    })
})
  