export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            formatPrice: ( amount, v = '' ) => {
                if (isNaN(amount)) {
                    return '-'
                }
                let currency = 'USD'
                if(nuxtApp.$country && nuxtApp.$country.location === 'IN' ){
                    currency = 'INR'
                }

                let formatPounds = null
  
                let fromRate = 1;
                let toRate  = 1;
                let finalValue = 1

                if ( currency == 'USD') {

                formatPounds = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0});
                }

                if (currency == 'GBP') {

                // toRate = 0.819
                formatPounds = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0});
                }


                if (currency == 'EUR') {
                
                // toRate = 0.924
                formatPounds = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0});
                }

                if (currency == 'INR') {
                
                // toRate = 80
                formatPounds = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0});
                }


                finalValue = ((toRate / fromRate) * amount).toFixed(2);

                return formatPounds.format(finalValue)

            }
        }
    }
})