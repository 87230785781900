<template>
    <div class="contact_box">
        <a rel="nofollow" class="tooltip_box" :href="'tel:'+$country.mobile">
            <SvgHeaderSvg :type="'telephone'" />
            <span class="tooltiptext">Phone</span>
        </a>    
        <div class="chat_box tooltip_box" @click="openTawkChat">
            <SvgHeaderSvg :type="'chat'" />
            <span class="tooltiptext">Chat</span>
        </div>
        <a v-if="$country.is_india" rel="nofollow" class="tooltip_box" target="_blank" href="https://wa.me/9920987654">
            <SvgHeaderSvg :type="'whatsapp'" />
            <span class="tooltiptext">WhatsApp</span>
        </a>
        <a rel="nofollow" class="tooltip_box" :href="'mailto:'+$country.email">
            <SvgHeaderSvg :type="'email'" />
            <span class="tooltiptext">Email</span>
        </a>
        <NuxtLink class="tooltip_box" :to="localePath('/appointment')">
            <SvgHeaderSvg :type="'virtual'" /> 
            <span class="tooltiptext">Book An Appointment</span>
        </NuxtLink>
        
        <div @click="openMenu" class="hambuger for_mobile_mod">
            <SvgHeaderSvg :type="'hamburger'" />
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp() 
    
    const props = defineProps(['locale'])
    const emit = defineEmits(['openMenu'])

    const openMenu = () => {
    emit('openMenu')
    }
    // const openTawkChat = () => {
    //     if (typeof window !== 'undefined' && window.Tawk_API) {
    //         window.Tawk_API.toggle()
    //     }
    // }

    // Method to handle Tawk.to chat
    const openTawkChat = () => {
        try {
            if (import.meta.client && window.Tawk_API) {
            window.Tawk_API.toggle();
        }
        } catch (error) {
            console.log(error);
        }
    };
</script>

<style scoped>

/* -----------tooltip------------- */
.tooltip_box {
  position: relative;
}

.tooltip_box .tooltiptext {
    display: none;
    width: -moz-max-content;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 1px 8px;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    font-weight: 600;
    top: -22px;
    left: 50%;
    transform: translate(-50%);
}

.tooltip_box:hover .tooltiptext {
  display: block;
}
/* -----------tooltip------------- */
.contact_box {
    width: 33.33%;
    display: flex;
    align-items: center;
}
.contact_box a, .chat_box {
    color: #000;
    display: flex;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}
.contact_box a span, .chat_box span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
}

@media(max-width: 1300px){
    .contact_box a, .chat_box {
        margin-right: 8px;
    }
}
@media(max-width: 1380px){
    .contact_box a span:nth-child(2), .chat_box span{
        display: none;
    }
}

@media(max-width: 1024px){
    .contact_box {
        width: 25%;
    }
    .hambuger {
        z-index: 99;
        position: relative;
        margin-top: -10px;
    }
    .contact_box a, .chat_box{
        display: none;
    }
}
@media(max-width: 767px){
    .contact_box {
        width: 20%;
    }
}
</style>
