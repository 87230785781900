// plugins/vue-tel-input.js
import { defineNuxtPlugin } from '#app'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.vueApp.use(VueTelInput, {
  //   mode: 'auto', // Define default global options here (optional)
  //   // Any other default options
  // })
})
