<template>
    <div class="toast_block" :class="variant" v-if="openToast">
        <div class="toast_title">
            <h5 class="mb-0">{{ title }}</h5>
            <div class="toast_close" @click="openToast = false"><SvgHeaderSvg :type="'close'" /></div>
        </div>
        <div class="toast_desc">
            <p class="mb-0">{{ message }}</p>
        </div>
    </div>
</template>

<script setup>

    const { variant, title, message } = defineProps(['variant', 'title', 'message'])
    
    const openToast = ref(true)

    onMounted(() =>{
        setTimeout(() => {
           openToast.value = false;
        }, 3000);
    })
    
</script>

<style scoped>
.toast_block {
    position: fixed;
    top: 15px;
    right: 15px;
    width: 350px;
    z-index: 9999;
    border-radius: 5px;
    transition: all 1s;
}

.toast_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px;
    border-radius: 5px 5px 0 0;
}
h5 {
    font-size: 14px;
    font-weight: 600;
}
.toast_desc {
    padding: 12px;
}
.toast_desc p {
    font-size: 14px;
}
.toast_close {
    cursor: pointer;
}

/* ----- success ----- */
.toast_block.success{
    background: rgba(212,237,218,.85);

}
.toast_block.success .toast_title{
    border-bottom: 1px solid var(--green);
    background: #668b8438;
}
.toast_block.success p{
    color: #155724;
}
.toast_block.success h5{
    color: #155724;
}


/* ----- alert ----- */
.toast_block.alert p{
    color: #721c24;
}
.toast_block.alert h5{
    color: #721c24;
}
.toast_block.alert{
    background: rgba(252,237,238,.85);
    padding: 0;
}
.toast_block{
    animation: 0.3s ease-in-out 0s 1 normal none running flashmsg;
}
@keyframes flashmsg {
    0% {
        top: -20%;
    }
    50% {
        top: -10%;
    }
    100% {
        top: 0%;
    }
}
.toast_block.alert .toast_title{
    border-bottom: 1px solid rgba(248,215,218,.85);
    background: rgba(248,215,218,.85);
}


@media(max-width: 767px){
    .toast_block{
        left: 50%;
        transform: translate(-50%);
    }
}
</style>
